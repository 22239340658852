/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from '@syncfusion/ej2-react-grids';
import swal from 'sweetalert';

function DialogEdit() {
  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5 };
  const [user, setUser] = useState([]);
  const gridRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/vehicle')
    .then((response) => response.json())
    .then((data) => setUser(data));

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (user.data) {
      const locations = [...new Set(user.data.map((item) => item.Location))];
      setLocationOptions(locations);
    }
  }, [user]);

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    const data = user.data.filter((item) => item.Location === selectedLocation);
    setFilteredData(data);
  };
  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/vehicle/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent dataSource={(filteredData.length > 0) ? filteredData : user.data} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)} ref={gridRef}>
          <ColumnsDirective>
            <ColumnDirective field="VehicleName" headerText="Vehicle Name" width="120" textAlign="Center" />
            <ColumnDirective field="VehicleNumber" headerText="Vehicle Number" width="150" textAlign="Center" />
            <ColumnDirective field="Insurance" headerText="Insurance End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="RoadTax" headerText="Road-Tax End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="Fitness" headerText="Fitness End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="PollutionTest" headerText="Pollution Test End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="File" headerText="Document" width="150" textAlign="Center" />
            <ColumnDirective field="Location" headerText="Location" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
          <div className="flex justify-end">
            <div className="select-wrap">
              <DropDownListComponent className="border" id="ddlelement" dataSource={locationOptions} placeholder="Filter By Location" change={handleLocationFilter} width="200px" />
            </div>
          </div>
        </GridComponent>
      </div>
    </div>
  );
}
export default DialogEdit;
