import React, { useState } from 'react';
import { Text, Input, Grid, Button, Dropdown } from '@nextui-org/react';
import axios from 'axios';
import swal from 'sweetalert';

export default function ShopRegistration() {
  const [selected, setSelected] = React.useState(new Set(['Select Branch']));
  const numbers = ['919531891302', '919434279729', '919933240857'];

  const selectedValue = React.useMemo(() => Array.from(selected).join(', ').replaceAll('_', ' '), [selected]);
  const [RegistrationNumber, setRegistrationNumber] = useState('');
  const [RegistrationDate, setRegistrationDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [ShopDoc, setShopDoc] = useState('');
  function guardarArchivo(e) {
    const file = e.target.files[0]; // the file
    const reader = new FileReader(); // this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); // start conversion...
    // eslint-disable-next-line func-names
    reader.onload = function () { // .. once finished..
      const rawLog = reader.result.split(',')[1]; // extract only thee file data part
      const dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: 'uploadFilesToGoogleDrive' }; // preapre info to send to API
      fetch(
        'https://script.google.com/macros/s/AKfycbwXuATO0YsuILYvSpjt-3s4NTAyvW-JATbLBjgRPB1wX1GbhrxKbOHY2xLkpHJ9pckh/exec', // your AppsScript URL
        { method: 'POST', body: JSON.stringify(dataSend) },
      ) // send to Api
        .then((res) => res.json()).then((a) => {
          setShopDoc((a.url));
          swal({
            title: 'File Uploaded!',
            text: `Check Link${a.url}`,
            icon: 'success',
            button: 'Continue',
          });
        }).catch((err) => swal({
          title: 'File Not Uploaded!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Try Again',
        }));
    };
  }
  function sendMessages(number) {
    // eslint-disable-next-line no-restricted-syntax
    for (const num of number) {
      fetch('https://graph.facebook.com/v15.0/117079414590887/messages', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer EAAHGdZBCfZC7kBAJCSu801OfZCMdE8z3yRMxQKH3sZAExXy9j1n3VqeJbREcwYHQiMCbYDXVfxMT8jtfXUwX0dfGKdbZB9lorjWsYRYMbvZC9H2wO4MvItE0JnOCYxIuvqrxFGBBwPq2EV6T1y8kW46DFn5ZCZCG32KpuvmVu1NZAnRmYPNXOXKZBZBkyHemScpIvWU9e6yGzfikwZDZD',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messaging_product: 'whatsapp',
          to: num,
          type: 'template',
          template: {
            name: 'hello_world ',
            language: {
              code: 'en_US',
            },
          },
        }),
      })
        .then((response) => response.json())
      // eslint-disable-next-line no-console
        .then((data) => console.log(data));
    }
  }
  const submitClick = async () => {
    await axios
      .post('https://gapi.nxtunicorns.com/api/shop', {
        Branch: selectedValue,
        RegistrationNumber,
        RegistrationDate,
        Document: ShopDoc,
        endDate,
      }, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        sendMessages(numbers);
        swal({
          title: 'New Shop Registration Submitted SuccessFully!',
          text: 'Check Data Column',
          icon: 'success',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Submit Failed!',
          text: `Network error ${err}`,
          icon: 'error',
          button: 'Try Again',
        });
      });
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: '45deg, $blue600 -20%, $blue600 50%',
        }}
        weight="bold"
      >
        SHOP REGISTRATION DATA ENTRY
      </Text>
      <Grid fluid>
        <Input label="Registration Number" type="text" placeholder="Enter Registration Number" onChange={(e) => setRegistrationNumber(e.target.value)} value={RegistrationNumber} />
      </Grid>
      <p>Select Location</p>
      <Dropdown>
        <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
          {selectedValue}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={setSelected}
        >
          <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
          <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
          <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
          <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
          <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
          <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
          <Dropdown.Item key="Goenkas Trends">Goenkas Trends</Dropdown.Item>
          <Dropdown.Item key="Zola">Zola</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown><br />
      <Grid>
        <Input label="Upload Document" type="file" onChange={(e) => guardarArchivo(e)} />
      </Grid>
      <Grid>
        <Input width="186px" label="Registration Date" type="date" onChange={(e) => setRegistrationDate(e.target.value)} value={RegistrationDate} />
      </Grid>
      <Grid>
        <Input width="186px" label="Registration Due Date" type="date" onChange={(e) => setendDate(e.target.value)} value={endDate} />
      </Grid>
      <br />
      <Grid>
        <Button shadow color="primary" auto onClick={submitClick}>Submit</Button>
      </Grid>
    </div>
  );
}

