/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from '@syncfusion/ej2-react-grids';
import swal from 'sweetalert';

function DialogEdit() {
  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5 };
  const [user, setUser] = useState([]);
  const [branches, setBranches] = useState([]);
  const gridRef = useRef(null);
  const [filteredData, setFilteredData] = useState([]);

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/shop')
    .then((response) => response.json())
    .then((data) => {
      setUser(data);
      const uniqueBranches = [...new Set(data.data.map((item) => item.Branch))];
      setBranches(uniqueBranches);
    });

  useEffect(() => {
    fetchData();
  }, []);

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    const data = user.data.filter((item) => item.Branch === selectedLocation);
    setFilteredData(data);
  };

  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/shop/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }

  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="flex justify-end">
          <div className="select-wrap">
            <DropDownListComponent className="border" id="ddlelement" dataSource={branches} placeholder="Filter By Location" width="200px" change={handleLocationFilter} />
          </div>
        </div>
        <GridComponent dataSource={(filteredData.length > 0) ? filteredData : user.data} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)} ref={gridRef}>
          <ColumnsDirective>
            <ColumnDirective field="Branch" headerText="Branch" width="120" textAlign="Center" />
            <ColumnDirective field="RegistrationNumber" headerText="Registration Number" width="150" textAlign="Center" />
            <ColumnDirective field="RegistrationDate" headerText="Registration Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="endDate" headerText="Registration End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="Document" headerText="Document" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
}
export default DialogEdit;
