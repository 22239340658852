/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from '@syncfusion/ej2-react-grids';
import swal from 'sweetalert';

function DialogEdit() {
  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5 };
  const [user, setUser] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const gridRef = useRef(null);

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/bill')
    .then((response) => response.json())
    .then((data) => setUser(data));

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const sum = user.data ? user.data.reduce((total, bill) => parseInt(total, 10) + parseInt(bill.BillAmount, 10), 0) : 0;
    setTotalValue(sum);
  }, [user]);

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    const filteredData = user.data.filter((item) => item.Location === selectedLocation);
    gridRef.current.dataSource = filteredData;
    gridRef.current.refresh();
  };

  const uniqueLocations = user.data ? user.data.reduce((locations, item) => {
    if (!locations.includes(item.Location)) {
      locations.push(item.Location);
    }
    return locations;
  }, []) : [];
  const dropdownData = uniqueLocations.map((location) => ({ text: location, value: location }));

  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/bill/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }

  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="flex justify-end">
          {user.data && (
            <div className="select-wrap">
              <DropDownListComponent className="border" id="ddlelement" dataSource={dropdownData} placeholder="Filter By Location" fields={{ text: 'text', value: 'value' }} change={handleLocationFilter} width="200px" />
            </div>
          )}
        </div>
        <GridComponent dataSource={(user.data)} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field="BillNumber" headerText="Bill Number" width="120" textAlign="Center" />
            <ColumnDirective field="BillAmount" headerText="Bill Amount" width="150" textAlign="Center" />
            <ColumnDirective field="BillDate" headerText="Bill Date" width="150" editType="datepickeredit" textAlign="Center" format="yMd" />
            <ColumnDirective field="BillDueDate" headerText="Bill Due Date" width="150" editType="datepickeredit" textAlign="Center" format="yMd" />
            <ColumnDirective field="Billtype" headerText="Bill Type" textAlign="Center" width="170" />
            <ColumnDirective field="BillRecipt" headerText="Bill Receipt" width="150" textAlign="Center" />
            <ColumnDirective field="Location" headerText="Location" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
        <div className="pl-10 mt-2">Total Bill Amount: <span className="font-bold">{totalValue}</span></div>
      </div>
    </div>
  );
}
export default DialogEdit;
