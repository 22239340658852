import React from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

import glogo from '../data/goenkas2.png';
// import wedding from '../data/bride-groom.jpg';
// import background from '../data/bglogReg.png';
import { useStateContext } from '../contexts/ContextProvider';

export default function Register() {
  // const [name, setName] = useState('');
  // const [password, setPassword] = useState('');
  // const [cpassword, setCpassword] = useState('');
  // const navigate = useNavigate();
  const { register, updateUserName } = useStateContext();

  const registerUser = async (event) => {
    event.preventDefault();

    const from = event.target;
    const name = from.name.value;
    const email = from.email.value;
    const password = from.password.value;
    register(email, password)
      .then(({ user }) => {
        console.log(user);
        const upUser = {
          displayName: name,
        };
        updateUserName(upUser)
          .then(() => {
            alert('User Registration is successfull');
          })
          .catch((error) => {
            console.error(error);
            alert('User Registration is Not successfull');
          });
      })
      .catch((error) => console.error(error));
    // const body = {
    //     name: name,
    //     email: email,
    //     password: password
    // }
    // const response = await axios.post('https://localhost:5000/register', body)
    // const data = await response.data

    // const response = await fetch('http://localhost:5000/register', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     name,
    //     password,
    //     cpassword,
    //   }),
    // });

    // const data = await response.json();

    // if (data.status === 'OK') {
    //   alert('User Registered Successfully!');
    //   window.location.href = '/loginpage';
    // } else {
    //   alert('User Registration Failed!');
    // }
  };

  return (
    <div
      className="w-full py-10 bg-gradient-to-tr from-indigo-900 via-violet-900 to-indigo-900"
    //   style={
    // { backgroundImage: `url(${background})`,
    // }
    //   }
    >
      <div className="w-full mx-auto p-6">
        <div className="backdrop-contrast-125 bg-white/30 lg:flex-row w-full lg:w-6/12 mx-auto rounded-lg py-20 drop-shadow-2xl">
          <img className="w-2/5 mx-auto mb-6" src={glogo} alt="goenka's logo" />
          <div className="w-full px-12">
            <h2 className="text-4xl mb-10 font-bold text-slate-300 text-center">Create User</h2>
            <form onSubmit={registerUser}>
              <div className="mt-5">
                <input
                  type="text"
                  placeholder="Full Name"
                  className="backdrop-invert bg-white/30 placeholder-slate-100 py-3 px-4 w-full text-white"
                  name="name"
                />
              </div>
              <div className="mt-5">
                <input
                  type="text"
                  placeholder="Email"
                  className="backdrop-invert bg-white/30 placeholder-slate-100 py-3 px-4 w-full text-white"
                  name="email"
                />
              </div>
              <div className="mt-5">
                <input
                  type="password"
                  placeholder="Password"
                  className="backdrop-invert bg-white/30 placeholder-slate-100 py-3 px-4 w-full"
                  name="password"
                />
              </div>
              <div className="mt-5">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="backdrop-invert bg-white/30 placeholder-slate-100 py-3 px-4 w-full text-white"
                />
              </div>
              <div className="mt-5">
                <input
                  type="submit"
                  className="w-full bg-gradient-to-r from-slate-900 to-slate-700 py-3 text-center text-white cursor-pointer"
                  value="Register Now"
                />
              </div>
              <div className="mt-5 mx-auto text-center">
                <a href="/loginpage" className="mt-5 text-center font-display hover:text-sky-700">
                  Already a user? ... Click Here
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
