/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from '@syncfusion/ej2-react-grids';
import swal from 'sweetalert';

function DialogEdit() {
  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5 };
  const [user, setUser] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const gridRef = useRef(null);
  const [uniqueLocations, setUniqueLocations] = useState([]);

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/tax')
    .then((response) => response.json())
    .then((data) => setUser(data));

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const sum = user.data ? user.data.reduce((total, tax) => parseInt(total, 10) + parseInt(tax.TaxValue, 10), 0) : 0;
    setTotalValue(sum);
  }, [user]);

  useEffect(() => {
    const locations = user.data?.map((item) => item.Location) || [];
    const uniqueLocationss = [...new Set(locations)];
    setUniqueLocations(uniqueLocationss);
  }, [user.data]);

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    const filteredData = user.data.filter((item) => item.Location === selectedLocation);
    gridRef.current.dataSource = filteredData;
    gridRef.current.refresh();
  };

  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/tax/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }
  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="flex justify-end">
          <div className="select-wrap">
            <DropDownListComponent className="border" id="ddlelement" dataSource={uniqueLocations} placeholder="Filter By Location" fields={{ text: 'Location', value: 'Location' }} change={handleLocationFilter} width="200px" />
          </div>
        </div>
        <GridComponent dataSource={(user.data)} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field="TaxName" headerText="Tax Type" width="120" textAlign="Center" />
            <ColumnDirective field="TaxValue" headerText="Tax Amount" width="150" textAlign="Center" />
            <ColumnDirective field="TaxPaymentDate" headerText="Tax Payment End Date" width="120" textAlign="Center" format="ydM" />
            <ColumnDirective field="TaxDocument" headerText="Document" width="150" textAlign="Center" />
            <ColumnDirective field="Location" headerText="Location" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
        <div className="pl-10 mt-2">Total Tax Amount: <span className="font-bold">{totalValue}</span></div>
      </div>
    </div>
  );
}
export default DialogEdit;
