import React, { useState } from 'react';
import { Text, Input, Grid, Dropdown, Button } from '@nextui-org/react';
import axios from 'axios';
import swal from 'sweetalert';

export default function TaxPayment() {
  const [selected, setSelected] = React.useState(new Set(['Select Tax type']));

  const selectedValue = React.useMemo(() => Array.from(selected).join(', ').replaceAll('_', ' '), [selected]);
  const [selectedloc, setSelectedloc] = React.useState(new Set(['Select Location']));
  const selectedValueloc = React.useMemo(() => Array.from(selectedloc).join(', ').replaceAll('_', ' '), [selectedloc]);
  const [TaxValue, setTaxValue] = useState('');
  const [TaxPaymentDate, setTaxPaymentDate] = useState('');
  const [TaxDocument, setTaxDocument] = useState('');
  function guardarArchivo(e) {
    const file = e.target.files[0]; // the file
    const reader = new FileReader(); // this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); // start conversion...
    // eslint-disable-next-line func-names
    reader.onload = function () { // .. once finished..
      const rawLog = reader.result.split(',')[1]; // extract only thee file data part
      const dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: 'uploadFilesToGoogleDrive' }; // preapre info to send to API
      fetch(
        'https://script.google.com/macros/s/AKfycbwXuATO0YsuILYvSpjt-3s4NTAyvW-JATbLBjgRPB1wX1GbhrxKbOHY2xLkpHJ9pckh/exec', // your AppsScript URL
        { method: 'POST', body: JSON.stringify(dataSend) },
      ) // send to Api
        .then((res) => res.json()).then((a) => {
          setTaxDocument((a.url));
          swal({
            title: 'File Uploaded!',
            text: `Check Link${a.url}`,
            icon: 'success',
            button: 'Continue',
          });
        }).catch((err) => swal({
          title: 'File Not Uploaded!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Try Again',
        })); // Or Error in console
    };
  }
  const submitClick = async () => {
    await axios
      .post('https://gapi.nxtunicorns.com/api/tax', {
        TaxName: selectedValue,
        TaxValue,
        TaxPaymentDate,
        TaxDocument,
        Location: selectedValueloc }, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        swal({
          title: 'Tax Payment Submitted SuccessFully!',
          text: 'Check Data Column',
          icon: 'success',
          button: 'Continue',
        });
      })
      .catch((err) => {
        swal({
          title: 'Error Empty Field!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Check Again',
        });
      });
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: '45deg, $blue600 -20%, $blue600 50%',
        }}
        weight="bold"
      >
        TAX DATA ENTRY
      </Text>
      <p>Select Tax Type</p>
      <Dropdown>
        <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
          {selectedValue}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={setSelected}
        >
          <Dropdown.Item key="propertytax">Property Tax</Dropdown.Item>
          <Dropdown.Item key="watertax">Water Tax</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown><br />
      <p>Select Location</p>
      <Dropdown>
        <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
          {selectedValueloc}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selectedloc}
          onSelectionChange={setSelectedloc}
        >
          <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
          <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
          <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
          <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
          <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
          <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
          <Dropdown.Item key="Goenkas Trends">Goenkas Trends</Dropdown.Item>
          <Dropdown.Item key="Zola">Zola</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown><br />
      <Grid>
        <Input label="Upload Document" type="file" onChange={(e) => guardarArchivo(e)} />
      </Grid>
      <Grid>
        <Input label="Tax Amount" type="number" placeholder="Enter Tax Value" onChange={(e) => setTaxValue(e.target.value)} value={TaxValue} />
      </Grid>
      <Grid>
        <Input width="186px" label="Tax Payment End Date" type="date" onChange={(e) => setTaxPaymentDate(e.target.value)} value={TaxPaymentDate} />
      </Grid><br />
      <Grid>
        <Button shadow color="primary" auto onClick={submitClick}>Submit</Button>
      </Grid>
    </div>
  );
}
