/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from '@syncfusion/ej2-react-grids';
import swal from 'sweetalert';
import axios from 'axios';

function DialogEdit() {
  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5, footerSum: true };
  const [user, setUser] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const gridRef = useRef(null);
  const [uniqueLocations, setUniqueLocations] = useState([]);

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/assets')
    .then((response) => response.json())
    .then((data) => setUser(data));

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const sum = user.data ? user.data.reduce((total, asset) => parseInt(total, 10) + parseInt(asset.AssetValue, 10), 0) : 0;
    setTotalValue(sum);
  }, [user]);
  useEffect(() => {
    const locations = user.data?.map((item) => item.AssetLocation) || [];
    const uniqueLocationss = [...new Set(locations)].map((location) => ({ text: location, value: location }));
    setUniqueLocations(uniqueLocationss);
  }, [user.data]);

  const handleLocationFilter = (args) => {
    if (args && args.value) {
      const selectedLocation = args.value;
      const filteredData = user.data.filter((item) => item.AssetLocation === selectedLocation);
      gridRef.current.dataSource = filteredData;
      gridRef.current.refresh();
    }
  };

  async function actionComplete(args) {
    console.log(args);
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/assets/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        } else {
          swal({
            title: 'Unable Deleted!',
            text: 'UNABLE DELETED',
            icon: 'error',
            button: 'Try Again',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'save') {
      console.log(args.data.id);
      await axios
        .put('https://gapi.nxtunicorns.com/api/assets', {
          id: args.data.id,
          AssetName: args.data.AssetName,
          AssetValue: args.data.AssetValue,
          AssetLocation: args.data.AssetLocation,
          AssetQuantity: args.data.AssetQuantity,
          DateOfPurchase: args.data.DateOfPurchase,
          AssetWarranty: args.data.AssetWarranty,
          BillNumber: args.data.BillNumber,
          AssetType: args.data.AssetType,
          BrandName: args.data.BrandName,
          AssetBill: args.data.AssetBill,
          PartyName: args.data.PartyName,
          AsssetWarrentyPeriod: args.data.AsssetWarrentyPeriod,
        }, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          // eslint-disable-next-line no-console
          console.log(response);
          swal({
            title: 'Updated Asset Submitted SuccessFully!',
            text: 'Check Data Column',
            icon: 'success',
            button: 'Continue',
          });
        })
        .catch((err) => {
          swal({
            title: 'Submit Failed!',
            text: `Network error ${err}`,
            icon: 'error',
            button: 'Try Again',
          });
        });
    }
  }
  return (
    <div className="control-pane">
      <div className="control-section">
        {uniqueLocations.length > 0 && (
          <div className="flex justify-end">
            <div className="select-wrap">
              <DropDownListComponent className="border" id="ddlelement" dataSource={uniqueLocations} placeholder="Filter By Location" change={handleLocationFilter} width="200px" />
            </div>
          </div>
        )}
        <GridComponent dataSource={(user.data)} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field="AssetName" headerText="AssetName" width="120" textAlign="Center" />
            <ColumnDirective field="AssetValue" headerText="Asset Value" width="150" textAlign="Center" />
            <ColumnDirective field="AssetLocation" headerText="Asset Location" width="120" textAlign="Center" />
            <ColumnDirective field="AssetQuantity" headerText="Asset Quantity" textAlign="Center" width="170" />
            <ColumnDirective field="DateOfPurchase" headerText="DOP" width="150" editType="datepickeredit" textAlign="Center" format="yMd" />
            <ColumnDirective field="AssetWarranty" headerText="Asset Warranty" width="120" textAlign="Center" />
            <ColumnDirective field="PartyName" headerText="Party Name" width="150" textAlign="Center" />
            <ColumnDirective field="BillNumber" headerText="Bill Number" width="120" textAlign="Center" />
            <ColumnDirective field="AssetType" headerText="Asset Type" textAlign="Center" width="170" />
            <ColumnDirective field="BrandName" headerText="Brand Name" width="150" textAlign="Center" />
            <ColumnDirective field="AssetBill" headerText="Asset Bill" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
        <div className="pl-10 mt-2">Total Asset Value: <span className="font-bold">{totalValue}</span></div>
      </div>
    </div>
  );
}
export default DialogEdit;
