/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Inject } from '@syncfusion/ej2-react-grids';
import swal from 'sweetalert';

function DialogEdit() {
  const toolbarOptions = ['Edit', 'Delete', 'Search'];
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Dialog' };
  const pageSettings = { pageCount: 5 };
  const [user, setUser] = useState([]);
  const gridRef = useRef(null);
  const [uniqueLocations, setUniqueLocations] = useState([]);

  const fetchData = () => fetch('https://gapi.nxtunicorns.com/api/employee')
    .then((response) => response.json())
    .then((data) => setUser(data));

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    const locations = user.data?.map((item) => item.Location) || [];
    const uniqueLocationss = [...new Set(locations)];
    setUniqueLocations(uniqueLocationss);
  }, [user.data]);

  const handleLocationFilter = (args) => {
    const selectedLocation = args.value;
    const filteredData = user.data.filter((item) => item.Location === selectedLocation);
    gridRef.current.dataSource = filteredData;
    gridRef.current.refresh();
  };

  async function actionComplete(args) {
    console.log(args);
    if ((args.requestType === 'beginEdit' || args.requestType === 'add')) {
      console.log(args);
    }
    if (args.requestType === 'delete') {
      console.log(args.data[0].id);
      try {
        const response = await fetch(`https://gapi.nxtunicorns.com/api/employee/${args.data[0].id}`, {
          method: 'DELETE',
        });
        const datas = await response.json();
        if (datas !== null) {
          swal({
            title: 'Record Deleted!',
            text: 'DELETED',
            icon: 'success',
            button: 'Continue',
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (args.requestType === 'edit') {
      console.log(args);
    }
  }

  return (
    <div className="control-pane">
      <div className="control-section">
        <GridComponent ref={gridRef} dataSource={(user.data)} toolbar={toolbarOptions} allowPaging editSettings={editSettings} pageSettings={pageSettings} actionComplete={actionComplete.bind(this)}>
          <ColumnsDirective>
            <ColumnDirective field="name" headerText="Name" width="120" textAlign="Center" />
            <ColumnDirective field="title" headerText="Designation" width="150" textAlign="Center" />
            <ColumnDirective field="HiringDate" headerText="DOJ" width="120" textAlign="Center" format="yMd" editType="datepickeredit" />
            <ColumnDirective field="DOB" headerText="DOB" editType="datepickeredit" textAlign="Center" width="170" format="yMd" />
            <ColumnDirective field="DateofAnniversary" headerText="DOA" width="150" editType="datepickeredit" textAlign="Center" format="yMd" />
            <ColumnDirective field="Mobile" headerText="Mobile Number" width="120" textAlign="Center" />
            <ColumnDirective field="PresentAddress" headerText="Permanent Address" width="150" textAlign="Center" />
            <ColumnDirective field="CurrentAddress" headerText="Current Address" width="120" textAlign="Center" />
            <ColumnDirective field="GuardianName" headerText="Guardian Name" textAlign="Center" width="170" />
            <ColumnDirective field="GuardianRelation" headerText="Guardian Relation" width="150" textAlign="Center" />
            <ColumnDirective field="GuardianNo" headerText="Guardian Number" width="150" textAlign="Center" />
            <ColumnDirective field="EmployeeImage" headerText="Photo" width="150" textAlign="Center" />
            <ColumnDirective field="Location" headerText="Location" width="150" textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
          <div className="flex justify-end">
            <div className="select-wrap">
              <DropDownListComponent className="border" id="ddlelement" dataSource={uniqueLocations} placeholder="Filter By Location" fields={{ text: 'Location', value: 'Location' }} change={handleLocationFilter} width="200px" />
            </div>
          </div>
        </GridComponent>
      </div>
    </div>
  );
}
export default DialogEdit;
