// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import swal from 'sweetalert';

// import {
//   Card,
//   Spacer,
//   Button,
//   Text,
//   Input,
//   Container,
//   // Grid,
//   // Image,
// } from '@nextui-org/react';

// import { Mail } from './Mail';
// import { Password } from './Password';

// import loginn from '../data/login.jpg';
import glogo from '../data/goenkas2.png';
// import background from '../data/bglogReg.png';

import { useStateContext } from '../contexts/ContextProvider';

const LoginPage = () => {
  // const navigate = useNavigate();

  const {
    // setCurrentColor,
    // setCurrentMode,
    // currentMode,
    // activeMenu,
    // currentColor,
    // themeSettings,
    // setThemeSettings,
    // flag,
    setflag,
    // UserName,
    // setUserName,
    // PasswordIn,
    // setPassword,
    login,
  } = useStateContext();

  // const [name, setName] = useState('');
  // const [userPassword, setUserPassword] = useState('');

  // const loginUser = async (event) => {
  //   if (data.user) {
  //     localStorage.setItem('token', data.user);
  //     alert('Login successful');
  //     window.location.href = '/ecommerce';
  //   } else {
  //     alert('Wrong User credentials');
  //   }
  // };

  const loginUser = (event) => {
    event.preventDefault();
    const from = event.target;
    const email = from.email.value;
    const password = from.password.value;

    login(email, password)
      .then(({ user }) => {
        console.log(user);
        if (user.email === 'rakesh123@gmail.com') {
          setflag('login2');
        } else if (
          user
        ) {
          setflag('login1');
        } else {
          swal({
            title: 'Login Failed!',
            text: 'Incorrect Username or Password!',
            icon: 'error',
            button: 'Please Try Again!',
          });
        }
      })
      .catch((error) => console.error(error));

    // const response = await fetch('http://localhost:5000/login', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     name,
    //     userPassword,
    //   }),
    // });

    // const data = await response.json();

    // if (data.user) {
    //   localStorage.setItem('token', data.user);
    //   setflag('login1');
    //   // navigate('/ecommerce');
    // } else if (
    //   data.user.name === 'rakesh' && data.user.password === 'rakesh@123'
    // ) {
    //   setflag('login2');
    // } else {
    //   swal({
    //     title: 'Login Failed!',
    //     text: 'Incorrect Username or Password!',
    //     icon: 'error',
    //     button: 'Please Try Again!',
    //   });
    // }

    // if (data.user) {
    //   localStorage.setItem('token', data.user);
    //   alert('Login successful');
    //   window.location.href = '/ecommerce';
    // } else {
    //   alert('Wrong User credentials');
    // }
  };
  return (
    <div
      className="w-full py-10 bg-gradient-to-tr from-indigo-900 via-violet-800 to-indigo-900"
    //   style={
    // { backgroundImage: `url(${background})`,
    // }
    //   }
    >
      <div className="w-8/12 mx-auto p-6">
        <div className="backdrop-contrast-125 bg-white/30 lg:flex-row w-full lg:w-6/12 mx-auto rounded-lg py-20 drop-shadow-2xl">
          <img className="w-2/5 mx-auto mb-6" src={glogo} alt="goenka's logo" />
          <div className="w-full px-12">
            <h2 className="text-4xl mb-10 font-bold text-slate-300 text-center">Login Here</h2>
            <form onSubmit={loginUser}>
              <div className="mt-5">
                <p className="font-bold text-white text-xl mb-2">Email</p>
                <input
                  type="email"
                  placeholder="Type Your Email Here"
                  className="backdrop-invert bg-white/30 placeholder-slate-100 py-3 px-4 w-full text-white"
                  name="email"
                />
              </div>
              <div className="mt-5">
                <p className="font-bold text-white text-xl mb-2">Password</p>
                <input
                  type="password"
                  placeholder="Password"
                  className="backdrop-invert bg-white/30  placeholder-slate-100 py-3 px-4 w-full text-white"
                  name="password"
                />
              </div>
              <div className="mt-10">
                <input
                  type="submit"
                  className="w-full bg-gradient-to-r from-slate-900 to-slate-700 py-3 text-center text-white cursor-pointer"
                  value="Login Now"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
