/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
import { Text, Input, Grid, Button, Dropdown, Radio } from '@nextui-org/react';
import React, { useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';

export default function NewEmployee() {
  const [selected, setSelected] = React.useState(new Set(['Select Branch']));

  const selectedValue = React.useMemo(() => Array.from(selected).join(', ').replaceAll('_', ' '), [selected]);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [HiringDate, setHiringDate] = useState('');
  const [Mobile, setmobile] = useState('');
  const [GuardianName, setGuardianName] = useState('');
  const [GuardianRelation, setGuardianRelation] = useState('');
  const [Martial, setMartial] = useState('');
  const [GuardianNo, setGuardianNo] = useState('');
  const [EmployeeImage, setEmployeeImage] = useState('');
  const [DateofAnniversaryVal, setDateofAnniversary] = useState(null);
  const [CurrentAddress, setCurrentAddress] = useState('');
  const [PresentAddress, setPresentAddress] = useState('');
  const [DOB, setdob] = useState('');
  function guardarArchivo(e) {
    const file = e.target.files[0]; // the file
    const reader = new FileReader(); // this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); // start conversion...
    // eslint-disable-next-line func-names
    reader.onload = function () { // .. once finished..
      const rawLog = reader.result.split(',')[1]; // extract only thee file data part
      const dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: 'uploadFilesToGoogleDrive' }; // preapre info to send to API
      fetch(
        'https://script.google.com/macros/s/AKfycbwXuATO0YsuILYvSpjt-3s4NTAyvW-JATbLBjgRPB1wX1GbhrxKbOHY2xLkpHJ9pckh/exec', // your AppsScript URL
        { method: 'POST', body: JSON.stringify(dataSend) },
      ) // send to Api
        .then((res) => res.json()).then((a) => {
          setEmployeeImage((a.url));
          swal({
            title: 'File Uploaded!',
            text: `Check Link${a.url}`,
            icon: 'success',
            button: 'Continue',
          });
        }).catch((err) => swal({
          title: 'File Not Uploaded!',
          text: `error ${err}`,
          icon: 'error',
          button: 'Try Again',
        })); // Or Error in console
    };
  }
  const checkMartial = (event) => {
    if (Martial === 'YES') {
      setDateofAnniversary(event.target.value);
    }
  };
  const submitClick = async () => {
    await axios
      .post('https://gapi.nxtunicorns.com/api/employee', {
        name,
        title,
        HiringDate,
        Mobile,
        GuardianName,
        GuardianRelation,
        GuardianNo,
        EmployeeImage,
        Location: selectedValue,
        DOB,
        country: 'INDIA',
        DateofAnniversary: DateofAnniversaryVal || '00-00-0000',
        PresentAddress,
        CurrentAddress,
      }, { headers: { 'Content-Type': 'application/json' } })
      .then(async (response) => {
        // eslint-disable-next-line no-console
        console.log(response);
        swal({
          title: 'New Employee Added SuccessFully!',
          text: 'Check Employee Table',
          icon: 'success',
          button: 'Continue',
        });
        // const accountSid = 'AC7ce2c37654a676efeea3e74a2965e844';
        // const authToken = '14513963aa77b757ef47ee2ad8c2831c';
        // const from = 'whatsapp:+14155238886';
        // const to = 'whatsapp:+919531891302';
        // const message = 'MESSAGE_BODY';
        // axios.post(
        //   `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`,
        //   {
        //     body: message,
        //     from,
        //     to,
        //   },
        //   { auth: { username: accountSid, password: authToken } },
        // )
        //   // eslint-disable-next-line no-shadow
        //   .then((response) => {
        //     console.log(response.data);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
      })
      .catch((err) => {
        swal({
          title: 'Submit Failed!',
          text: `Network error ${err}`,
          icon: 'error',
          button: 'Check Fileds Try Again',
        });
      });
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Text
        h1
        size={30}
        css={{
          textGradient: '45deg, $blue600 -20%, $blue600 50%',
        }}
        weight="bold"
      >
        EMPLOYEE REGISTRATION
      </Text>
      <Grid>
        <Input label="Employee Name" name="name" type="text" placeholder="Enter Employee Name" onChange={(e) => setName(e.target.value)} value={name} />
      </Grid>
      <Grid>
        <Input label="Designation" name="title" type="text" placeholder="Enter Employee Designation" onChange={(e) => setTitle(e.target.value)} value={title} />
      </Grid>
      <Grid>
        <Input label="Upload Photo" name="EmployeeImage" type="file" placeholder="Upload File" onChange={(e) => guardarArchivo(e)} />
      </Grid>
      <Grid>
        <Input label="Current Address" name="Current Address" type="text" placeholder="Enter Employee Current Address" onChange={(e) => setCurrentAddress(e.target.value)} value={CurrentAddress} />
      </Grid>
      <Grid>
        <Input label="Permanent Address" name="Present Address" type="text" placeholder="Enter Employee Present Address" onChange={(e) => setPresentAddress(e.target.value)} value={PresentAddress} />
      </Grid>
      <Grid>
        <Input width="186px" label="Date of Birth" type="date" placeholder="DOB of Employee" onChange={(e) => setdob(e.target.value)} value={DOB} />
      </Grid>
      <Radio.Group label="Martial Status" value={Martial} name="Martial" onChange={setMartial} size="xs">
        <Radio value="YES">Yes</Radio>
        <Radio value="NO">No</Radio>
      </Radio.Group>
      {Martial === 'YES' ? (
        <Grid>
          <Input width="186px" label="Date of Anniversary" type="date" placeholder="Date of Anniversary" onChange={(e) => checkMartial(e)} value={DateofAnniversaryVal} />
        </Grid>
      ) : <>{' '}</>}
      <Grid>
        <Input width="186px" name="HiringDate" label="Date of Joining" type="date" placeholder="DOJ of Employee" onChange={(e) => setHiringDate(e.target.value)} value={HiringDate} />
      </Grid>
      <Grid>
        <Input label="Mobile Number" name="mobile" type="text" placeholder="Enter Employee Mobile Number" onChange={(e) => setmobile(e.target.value)} value={Mobile} />
      </Grid>
      <Grid>
        <Input label="Guardian Name" name="GuardianName" type="text" placeholder="Enter Guardian Name" onChange={(e) => setGuardianName(e.target.value)} value={GuardianName} />
      </Grid>
      <Grid>
        <Input label="Guardian Relation" name="GuardianRelation" type="text" placeholder="Enter Guardian Relation" onChange={(e) => setGuardianRelation(e.target.value)} value={GuardianRelation} />
      </Grid>
      <Grid>
        <Input label="Guardian Mobile Number" name="GuardianNo" type="number" placeholder="Enter Guardian Mobile Number" onChange={(e) => setGuardianNo(e.target.value)} value={GuardianNo} />
      </Grid><br />
      <p>Select Branch</p>
      <Dropdown>
        <Dropdown.Button flat color="secondary" css={{ tt: 'capitalize' }}>
          {selectedValue}
        </Dropdown.Button>
        <Dropdown.Menu
          aria-label="Single selection actions"
          color="secondary"
          disallowEmptySelection
          selectionMode="single"
          selectedKeys={selected}
          onSelectionChange={setSelected}
        >
          <Dropdown.Item key="Bamboo Flat">Bamboo Flat</Dropdown.Item>
          <Dropdown.Item key="Aberdeen Bazar">Aberdeen Bazar</Dropdown.Item>
          <Dropdown.Item key="Diglipur">Diglipur</Dropdown.Item>
          <Dropdown.Item key="Bathu Basti">Bathu Basti</Dropdown.Item>
          <Dropdown.Item key="Lamba Line">Lamba Line</Dropdown.Item>
          <Dropdown.Item key="Minnie Bay">Minnie Bay</Dropdown.Item>
          <Dropdown.Item key="Goenkas Trends">Goenkas Trends</Dropdown.Item>
          <Dropdown.Item key="Zola">Zola</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown><br />
      <Grid>
        <Button shadow color="primary" auto onClick={submitClick}>Submit</Button>
      </Grid>
    </div>
  );
}
